import React from "react";

export default function PageLoader() {
  return (
    <div className="flex items-center justify-center h-[300px]">
      <div role="status">
        <div className="animate-spin rounded-full h-14 w-14 border-b-2 border-primary"></div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
