import React, { useEffect, useState } from "react";
import { getClaimedZips, downloadCsvClaimedZips } from "../../requests/reports";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { usePagination } from "../UI/Pagination";
import { useSearchParams } from "react-router-dom";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import { Pagination } from "../UI/Pagination";
import { addressMultiLine } from "../../utils/fullAddress";
import Pill from "../UI/Pill";
import { usePageActions } from "../../contexts/PageActionsContext";
import { DownloadCSVIcon, PrintIcon } from "../UI/PageActions/Icons";
import { ToastNotification } from "../UI/ToastNotification";
import ClaimedZipsReportPrint from "./Print/ClaimedZipsReportPrint";

export default function ClaimedZipsReport() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printingReports, setPrintingReports] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      active: searchParams.get("status"),
      conference_code: searchParams.get("conference_code"),
      discovery_center: searchParams.get("discovery_center"),
      pagination: print ? false : undefined,
      page: csv || print ? undefined : page,
      per_page: csv || print ? undefined : pagination.recordsPerPage,
    };

    return queryParams;
  };

  const getReport = async (callback) => {
    setLoading(true);

    try {
      const res = await getClaimedZips({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data);
        } else {
          setReports(res.data);
          pagination.setData(res.data.pagination);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvClaimedZips({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    getReport();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || reports?.schools?.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || reports?.schools?.length < 1,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getReport((reports) => {
            setPrintingReports(reports);
            setIsPrinting(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    searchParams,
    reports,
    loading,
    isPrinting,
    downloadingCsv,
  ]);

  useEffect(() => {
    if (printingReports?.schools?.length > 0) window.print();

    return () => {
      if (printingReports?.schools?.length > 0) setPrintingReports([]);
    };
  }, [printingReports]);

  return (
    <>
      <div className="flex justify-between my-10">
        <p>Schools with Claimed Zips Report</p>
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && reports?.length < 1}
        empty={reports?.length < 1}
      >
        {reports.schools?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>NAME</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>CONFERENCE</TableHeader>
                <TableHeader>CLAIMED ZIPS</TableHeader>
                <TableHeader>STATUS</TableHeader>
              </tr>
            </thead>
            <tbody>
              {reports.schools.map((school) => (
                <tr key={school.id}>
                  <TableCell>
                    {`(${school.school_code})
                ${school.church_name}`}
                  </TableCell>
                  <TableCell classNames="p-2">
                    {addressMultiLine({
                      address: school.church_address,
                      address2: school.church_address_2,
                      city: school.church_city,
                      state: school.church_state,
                      zip: school.church_zip_code,
                    })}
                  </TableCell>
                  <TableCell>{school.conference?.name}</TableCell>
                  <TableCell>
                    {school.claimed_zips.map((claimed_zip) => (
                      <p key={claimed_zip.id}>{claimed_zip.zip}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {school.active ? (
                      <Pill color="success">Active</Pill>
                    ) : (
                      <Pill>Inactive</Pill>
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No schools with claimed zips." />
        )}
      </Pagination>

      <ClaimedZipsReportPrint reports={printingReports} />
    </>
  );
}
