import React from "react";

// Render an address with a highlighted field if specified.
// It can receive an object or array with the address fields as the first argument.
// It can receive an options object as the second argument to specify which field to highlight.
// Examples:
// renderAddress({ address: '123 Main St', city: 'Anytown', state: 'CA', zip_code: '12345' }, { highlight: 'city' });
// renderAddress(['123 Main St', 'Anytown', 'CA', '12345']); // no highlight function
export const renderAddress = (addressObj, options = {}) => {
  return Object.entries(addressObj)
    .filter(([, value]) => value)
    .map(([field, part]) => {
      if (field === options.highlight) {
        return (
          <span key={field} className="bg-yellow-200">
            {part}
          </span>
        );
      }
      return <React.Fragment key={field}>{part}</React.Fragment>;
    })
    .reduce(
      (prev, curr, index) =>
        [prev, index > 0 && ", ", curr].filter(Boolean).flat(),
      []
    );
};

// Render an address with line breaks with a highlighted field if specified.
// It should recei
// It should receive an options object as the second argument to specify which field to highlight.
// Examples:
// addressMultiLine({ address: '123 Main St', city: 'Anytown', state: 'CA', zip_code: '12345' }, { highlight: 'city' });
export const addressMultiLine = (addressObj, options = {}) => {
  const { address, address_2, city, state, zip_code, country } = addressObj;

  const parts = [
    { key: "address", value: address },
    { key: "address_2", value: address_2 },
    {
      key: "city_state_zip",
      value: [
        { key: "city", value: city },
        { key: "state", value: state },
        { key: "zip_code", value: zip_code },
      ]
        .filter((part) => part.value)
        .map((part, index, array) => ({
          ...part,
          isHighlighted: options.highlight === part.key,
          needsComma: index === 0 && array.length > 1,
        })),
    },
    { key: "country", value: country },
  ].filter((part) => part.value);

  return parts.map((part, index) => {
    let content;
    if (Array.isArray(part.value)) {
      content = part.value.map((subPart, subIndex) => (
        <React.Fragment key={subPart.key}>
          {subPart.isHighlighted ? (
            <span className="bg-yellow-200">{subPart.value}</span>
          ) : (
            subPart.value
          )}
          {subPart.needsComma && ", "}
          {subIndex < part.value.length - 1 && " "}
        </React.Fragment>
      ));
    } else {
      const isHighlighted = options.highlight === part.key;
      content = isHighlighted ? (
        <span className="bg-yellow-200">{part.value}</span>
      ) : (
        part.value
      );
    }

    return (
      <React.Fragment key={part.key}>
        {content}
        {index < parts.length - 1 && <br />}
      </React.Fragment>
    );
  });
};
