import React, { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getSchoolInformationForms } from "../../requests/reports";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import Button from "../UI/Button";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function SchoolInformationFormsReport() {
  const [searchParams] = useSearchParams();
  const [schools, setSchools] = useState([]);
  const [logo, setLogo] = useState("");
  let data = [];

  function getBase64FromImageUrl(url) {
    var img = new Image();

    img.setAttribute("crossOrigin", "anonymous");

    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(this, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      setLogo(dataURL);
    };

    img.src = url;
  }

  const docDefinition = () => {
    data = [];

    schools.map((school, index) => {
      data.push([
        {
          table: {
            widths: ["*", "*", "*", "*"],
            body: [
              [
                {
                  stack: [
                    {
                      image: "logo",
                      width: 70,
                    },
                    `School #${school.school_code}`,
                  ],
                  margin: [0, 0, 0, 10], // add some margin below the image
                },
                { text: "Information Form", italics: true },
                `Date \n Date Entered \n Last Update \n ${school.active ? "Active" : "Inactive"}`,
                `${dayjs().format("MMM D, YYYY")} \n ${dayjs(school.created_at).format("MMM D, YYYY")} \n ${dayjs(school.updated_at).format("MMM D, YYYY")}`,
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          ul: [
            {
              text: [
                { text: "Conference: ", bold: true },
                school.conference?.name,
              ],
              listType: "none",
            },
            {
              text: [{ text: "Church Name: ", bold: true }, school.church_name],
              listType: "none",
            },
            {
              table: {
                widths: ["*", 1000],
                body: [
                  [
                    { text: "Address: ", bold: true },
                    `${school.church_address} \n ${school.church_city}, ${school.church_state} ${school.church_zip_code} ${school.church_country === "Canada" ? `\n ${school.church_country}` : ""}`,
                  ],
                ],
              },
              layout: "noBorders",
              listType: "none",
            },
            {
              text: [{ text: "Phone: ", bold: true }, school.church_phone],
              listType: "none",
            },
            {
              text: [{ text: "Fax: ", bold: true }, school.church_fax],
              listType: "none",
            },
            {
              text: [
                { text: "E-mail Address: ", bold: true },
                school.church_email,
              ],
              listType: "none",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 515,
              y2: 0,
              lineWidth: 1,
              lineColor: "black",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          ul: [
            {
              text: [{ text: "School Name: ", bold: true }, school.school_name],
              listType: "none",
            },
            {
              table: {
                widths: ["*", 1000],
                body: [
                  [
                    { text: "Address: ", bold: true },
                    school.school_address
                      ? `${school.school_address} \n ${school.school_city}, ${school.school_state} ${school.school_zip_code} ${school.school_country === "Canada" ? `\n ${school.school_country}` : ""}`
                      : "No address",
                  ],
                ],
              },
              layout: "noBorders",
              listType: "none",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 515,
              y2: 0,
              lineWidth: 1,
              lineColor: "black",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          ul: [
            {
              text: [
                { text: "Head School Admin: ", bold: true },
                `${school.head_school_admin?.first_name} ${school.head_school_admin?.last_name}`,
              ],
              listType: "none",
            },
            {
              text: [
                { text: "E-mail Address: ", bold: true },
                school.head_school_admin?.email,
              ],
              listType: "none",
            },
            {
              table: {
                widths: ["*", 1000],
                body: [
                  [
                    { text: "Address: ", bold: true },
                    school.head_school_admin?.address
                      ? `${school.head_school_admin?.address} \n ${school.head_school_admin?.city}, ${school.head_school_admin?.state} ${school.head_school_admin?.zip_code} ${school.head_school_admin?.country === "Canada" ? `\n ${school.head_school_admin?.country}` : ""}`
                      : "No address",
                  ],
                ],
              },
              layout: "noBorders",
              listType: "none",
            },
            {
              text: [{ text: "Phone: ", bold: true }, school.church_phone],
              listType: "none",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 515,
              y2: 0,
              lineWidth: 1,
              lineColor: "black",
            },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          ul: [
            {
              text: [
                { text: "Claimed Zips: ", bold: true },
                school.claimed_zips.map((zip_code) => zip_code.zip).join(", "),
              ],
              listType: "none",
            },
          ],
        },
        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 0,
              x2: 515,
              y2: 0,
              lineWidth: 1,
              lineColor: "#d1d1d1",
            },
          ],
          margin: [0, 10, 0, 30],
        },
      ]);

      if (index < schools.length - 1) {
        data.push({ text: "", pageBreak: "after" });
      }
    });

    return {
      info: {
        title: "schools.pdf",
      },
      content: data,
      images: {
        logo: logo,
      },
    };
  };

  const handlePrint = () => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition());
    pdfDocGenerator.print();
  };

  const getReports = async () => {
    const res = await getSchoolInformationForms({
      params: {
        active: searchParams.get("status"),
        conference_code: searchParams.get("conference_code"),
        discovery_center: searchParams.get("discovery_center"),
        school_id: searchParams.get("school_id"),
      },
    });

    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  useEffect(() => {
    if (schools.length > 0 && data.length === 0) {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition());
      pdfDocGenerator.getDataUrl((dataUrl) => {
        document.querySelector("#pdfViewer").src = dataUrl;
      });
    }
  }, [schools]);

  useEffect(() => {
    getReports();
    getBase64FromImageUrl("/discover-bible-school-logo.png");
  }, [
    searchParams.get("status"),
    searchParams.get("conference_code"),
    searchParams.get("discovery_center"),
  ]);

  return (
    <div>
      <div className="flex justify-between my-10">
        <p>School Information Forms</p>
        <Button variant="primary" onClick={handlePrint}>
          Print All
        </Button>
      </div>
      <iframe id="pdfViewer" className="w-full h-[700px]"></iframe>
    </div>
  );
}
