import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchAsync from "../../UI/SearchAsync";
import { getAllSchools } from "../../../requests/schools";
import { getAllStudents } from "../../../requests/students";

export default function SearchInput({ admin }) {
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [selectedStudentId, setSelectedStudentId] = useState();
  const navigate = useNavigate();

  const handleSchoolChange = (e) => {
    setSelectedSchoolId(e?.value);
    navigate(`/schools/${e?.value}/overview`);
  };

  const handleStudentChange = (e) => {
    setSelectedStudentId(e?.value);
    navigate(`/students/${e?.value}/overview`);
  };

  const schoolsOptions = async (inputValue) => {
    const value = inputValue.trim().replace("#", "").split(" ");

    try {
      const response = await getAllSchools({
        params: {
          q: {
            school_code_or_church_name_or_church_city_or_church_state_or_church_zip_code_i_cont_all:
              value,
            s: "church_name asc",
          },
        },
      });

      return response.data.schools.map((school) => ({
        label: `(#${school.school_code}) ${school.church_name} (${school.church_city || ""}, ${school.church_state || ""} ${school.church_zip_code || ""}) (${school.students_count} students)`,
        value: school.id,
      }));
    } catch (error) {
      return [];
    }
  };

  const studentsOptions = async (inputValue) => {
    const value = inputValue.trim().split(" ");

    try {
      const response = await getAllStudents({
        params: {
          q: {
            full_name_i_cont_all: value,
            s: ["first_name asc", "middle_name asc", "last_name asc"],
          },
        },
      });

      return response.data.students.map((student) => ({
        label: `${student.first_name} ${student.middle_name} ${student.last_name}`,
        value: student.id,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="flex gap-4 w-full">
      {admin && (
        <SearchAsync
          light
          className="w-full"
          onChange={handleSchoolChange}
          placeholder="Schools"
          key={`school-search-${selectedSchoolId}`}
          loadOptions={schoolsOptions}
        />
      )}

      <SearchAsync
        light
        className="w-full"
        onChange={handleStudentChange}
        placeholder="Students"
        key={`student-search-${selectedStudentId}`}
        loadOptions={studentsOptions}
      />
    </div>
  );
}
