import React from "react";
import { Link } from "react-router-dom";
import { EnvelopeIcon, PrinterIcon } from "@heroicons/react/20/solid";

export default function PageActions({ schoolId }) {
  return (
    <div className="mb-4 flex justify-center">
      <Link
        to={`/reports?report_type=school_information_forms_report&school_id=${schoolId}`}
        className="mr-5 flex items-center cursor-pointer"
      >
        <PrinterIcon className="mr-2 w-5" />
        <p>Print Information</p>
      </Link>
      <Link
        to={`/reports?report_type=print_school_envelopes_report&schools_ids=${schoolId}`}
        className="flex items-center cursor-pointer"
      >
        <EnvelopeIcon className="mr-2 w-5" />
        <p>Print Envelope</p>
      </Link>
    </div>
  );
}
