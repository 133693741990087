import React, { useState, useEffect } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import {
  getAllStudents,
  downloadCsvStudentsRequest,
} from "../../requests/students";
import { fullName } from "../../utils/fullName";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import Button from "../UI/Button";
import QuickFind from "../UI/QuickFind";
import ListButtonGroup from "../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../UI/ListButtonGroup/ListButtonGroupItem";
import StudentsMobileList from "./Mobile/StudentsMobileList";
import Modal from "../UI/Modal";
import EditOnHover from "../UI/EditOnHover";
import PrintTicket from "./Student/PrintTicket";
import { checkPermission } from "../../utils/checkPermission";
import { returnEnrollmentStatus } from "../../utils/enrollmentStatus";
import { hoverLink } from "../../utils/hoverLink";
import dayjs from "dayjs";
import CSVReader from "react-csv-reader";
import { ArrowUpOnSquareIcon } from "@heroicons/react/16/solid";
import { addressMultiLine } from "../../utils/fullAddress";
import CsvStudents from "./CsvStudents";
import { ToastNotification } from "../UI/ToastNotification";
import { usePageActions } from "../../contexts/PageActionsContext";
import {
  ReassignIcon,
  ShareIcon,
  CancelSharingIcon,
  UploadCSVIcon,
  DownloadCSVIcon,
  PrintIcon,
} from "../UI/PageActions/Icons";
import ReassignStudents from "./Actions/ReassignStudents";
import ShareStudents from "./Actions/ShareStudents";
import UnshareStudents from "./Actions/UnshareStudents";
import { displayError } from "../../utils/displayError";
import { useFilterValues } from "../UI/Filter/Filter";
import StudentsFilter from "./StudentsFilter";
import StudentsPrint from "./StudentsPrint";

export default function Students() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const filterValues = useFilterValues();
  const { setActionMenuModal } = useOutletContext();
  const section = searchParams.get("section") || "all";
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "asc";
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [enableBulkActions, setEnableBulkActions] = useState(false);
  const [printTicketModal, setPrintTicketModal] = useState({
    open: false,
    student: null,
  });
  const tableNavigation = [
    { label: "All", name: "all" },
    { label: "Interests", name: "interests" },
    { label: "Enrolled", name: "enrolled" },
  ];
  const [students, setStudents] = useState([]);
  const [csvStudents, setCsvStudents] = useState([]);
  const [csvPreviewModal, setCsvPreviewModal] = useState({ open: false });
  const [refreshStudents, setRefreshStudents] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [reassignStudentsOpen, setReassignStudentsOpen] = useState(false);
  const [shareStudentsOpen, setShareStudentsOpen] = useState(false);
  const [unshareStudentsOpen, setUnshareStudentsOpen] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printingStudents, setPrintingStudents] = useState([]);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      section: section,
      page: csv || print ? undefined : page,
      per_page: print ? 10000 : csv ? undefined : pagination.recordsPerPage,
      q: { ...filterValues, sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      queryParams.q.full_name_i_cont_all = query.trim().split(" ");
    }

    return queryParams;
  };

  const getStudents = async (callback) => {
    setLoading(true);
    setSelectedStudents([]);

    try {
      const res = await getAllStudents({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data.students);
        } else {
          setStudents(res.data.students);
          pagination.setData(res.data.pagination);
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSection = (section) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", "");
      return prev;
    });
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  const csvParserOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleCsvData = (data) => {
    setCsvStudents(data);
    if (data.length > 0) {
      setCsvPreviewModal({ open: true });
    } else {
      ToastNotification("error", "There are no students in the CSV to import.");
    }
  };

  const handleStudentSelection = (studentId) => {
    setSelectedStudents((prev) => {
      if (prev.includes(studentId)) {
        return prev.filter((id) => id !== studentId);
      } else {
        return [...prev, studentId];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudents(students.map((student) => student.id));
    } else {
      setSelectedStudents([]);
    }
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvStudentsRequest({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    if (refreshStudents) {
      getStudents();
      setRefreshStudents(false);
    }
  }, [refreshStudents]);

  useEffect(() => {
    setEnableBulkActions(selectedStudents.length > 0);
  }, [selectedStudents]);

  useEffect(() => {
    setShowCsvUpload(false);
  }, [enableBulkActions]);

  // Fetch students when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getStudents();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: ReassignIcon,
        label: "Reassign Students",
        disabled: !enableBulkActions,
        onClick: () => {
          setReassignStudentsOpen(true);
        },
      },
      {
        icon: ShareIcon,
        label: "Share Students",
        disabled: !enableBulkActions,
        onClick: () => {
          setShareStudentsOpen(true);
        },
      },
      {
        icon: CancelSharingIcon,
        label: "Cancel Sharing",
        disabled: !enableBulkActions,
        onClick: () => {
          setUnshareStudentsOpen(true);
        },
      },
      {
        icon: UploadCSVIcon,
        label: "Upload CSV",
        onClick: () => {
          setShowCsvUpload((prev) => !prev);
        },
      },
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || students.length < 1,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || students.length < 1,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getStudents((students) => {
            setPrintingStudents(students);
            setIsPrinting(false);
            setLoading(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    enableBulkActions,
    downloadingCsv,
    searchParams,
    students,
    loading,
    isPrinting,
  ]);

  useEffect(() => {
    if (printingStudents.length > 0) window.print();

    return () => {
      if (printingStudents.length > 0) setPrintingStudents([]);
    };
  }, [printingStudents]);

  return (
    <>
      <div className="md:hidden">
        <StudentsMobileList students={students} />
      </div>
      <div className="hidden md:block">
        {showCsvUpload && (
          <div className="justify-center flex">
            <label
              className="d-flex justify-content-center align-items-center btn flex flex-col
              items-center justify-center p-4 border-dashed border border-black rounded-md mb-4 cursor-pointer"
            >
              <ArrowUpOnSquareIcon className="mr-2 w-5" />
              Click or drag students CSV to preview
              <CSVReader
                inputId="csv-reader-input"
                inputStyle={{
                  cursor: "pointer",
                  opacity: 0,
                  position: "absolute",
                  left: 0,
                  top: 210,
                  height: "100%",
                  width: "100%",
                }}
                onFileLoaded={handleCsvData}
                parserOptions={csvParserOptions}
              />
            </label>
          </div>
        )}

        <Card>
          <div className="flex items-center justify-between mb-6">
            <div className="relative">
              {checkPermission(["school_admin", "conference_admin"]) && (
                <img
                  src="/add-new.svg"
                  className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                  width={30}
                  height={30}
                  alt="Add New"
                  onClick={() =>
                    setActionMenuModal({
                      open: true,
                      defaultRender: "NewStudent",
                    })
                  }
                />
              )}

              <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
            </div>
            <ListButtonGroup>
              {tableNavigation.map((item, index) => (
                <ListButtonGroupItem
                  key={item.name}
                  label={item.label}
                  name={item.name}
                  isFirst={index === 0}
                  isLast={index === tableNavigation.length - 1}
                  setSelected={handleSection}
                  selected={section}
                />
              ))}
            </ListButtonGroup>
            <div className="flex relative">
              <QuickFind onSubmit={onSubmit} value={query} />
              <div className="relative ml-7">
                <StudentsFilter />
              </div>
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && students.length < 1}
            empty={students.length < 1}
          >
            <Table>
              <thead>
                <tr>
                  <TableHeader classNames="w-10 pb-2">
                    <div className="flex align-center items-start h-full pr-2">
                      <input
                        type="checkbox"
                        checked={selectedStudents.length === students.length}
                        onChange={handleSelectAll}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                  </TableHeader>
                  <TableHeader
                    isSortable
                    isMultiSort
                    multiSortOptions={[
                      { name: "First", value: "first_name" },
                      { name: "Last", value: "last_name" },
                    ]}
                    classNames="pb-2"
                  >
                    STUDENT
                  </TableHeader>
                  <TableHeader
                    isSortable
                    isMultiSort
                    sortBy="enrollments"
                    classNames="pb-2"
                    multiSortOptions={[
                      {
                        name: "Latest Lesson Sent",
                        value: "cached_latest_sent_date",
                      },
                      {
                        name: "Registration number",
                        value: "cached_registration_numbers",
                      },
                    ]}
                  >
                    ENROLLMENTS
                  </TableHeader>
                  <TableHeader
                    isSortable
                    isMultiSort
                    multiSortOptions={[
                      { name: "City", value: "city" },
                      { name: "State", value: "state" },
                      { name: "Zip", value: "zip_code" },
                      { name: "Country", value: "country" },
                    ]}
                  >
                    ADDRESS
                  </TableHeader>
                  <TableHeader isSortable sortBy="created_at" classNames="pb-2">
                    CREATED
                  </TableHeader>
                  <TableHeader
                    isSortable
                    sortBy="instructor_first_name"
                    classNames="pb-2"
                  >
                    INSTRUCTOR
                  </TableHeader>
                  <TableHeader
                    isSortable
                    sortBy="school_school_name"
                    classNames="pb-2"
                  >
                    SCHOOL
                  </TableHeader>
                  <TableHeader classNames="pb-2"></TableHeader>
                </tr>
              </thead>
              <tbody>
                {students.length > 0 &&
                  students.map((student) => (
                    <tr
                      key={student.id}
                      className={loading ? "opacity-25" : undefined}
                    >
                      <TableCell classNames="w-10">
                        <div className="flex items-start h-full pr-2">
                          <input
                            type="checkbox"
                            checked={selectedStudents.includes(student.id)}
                            onChange={() => handleStudentSelection(student.id)}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <EditOnHover to={`/students/${student.id}/profile`}>
                          <Link
                            className={`${hoverLink}`}
                            to={`/students/${student.id}/overview`}
                          >
                            {fullName(
                              student.first_name,
                              student.middle_name,
                              student.last_name
                            )}
                          </Link>
                        </EditOnHover>
                      </TableCell>
                      <TableCell classNames="text-sm min-w-[300px]">
                        {student.enrollments.map((enrollment) => (
                          <div
                            key={enrollment.id}
                            className="flex flex-row space-x-2"
                          >
                            {enrollment.series_type === "Seminar" ? (
                              <>
                                <div className="w-5/12">
                                  <Link
                                    to={`/students/${student.id}/seminars/enrollments/${enrollment.id}/presentations`}
                                    className={hoverLink}
                                  >
                                    {enrollment.series_name}
                                  </Link>
                                </div>
                                <div className="w-3/12 whitespace-nowrap text-xs">
                                  {enrollment.registration_number || "-"}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-5/12">
                                  <Link
                                    to={`/students/${student.id}/courses/enrollments/${enrollment.id}/lessons`}
                                    className={hoverLink}
                                  >
                                    {enrollment.series_name}
                                  </Link>
                                </div>
                                <div className="w-3/12 whitespace-nowrap text-xs">
                                  {enrollment.last_completed_delivery
                                    ? dayjs(
                                        enrollment.last_completed_delivery
                                          ?.date_completed
                                      ).format("MMM D, YYYY")
                                    : "No lessons"}
                                </div>
                              </>
                            )}
                            <div className="w-4/12 text-xs">
                              {returnEnrollmentStatus(
                                enrollment.series_id,
                                enrollment
                              )}
                            </div>
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {addressMultiLine(
                          {
                            address: student.address,
                            address_2: student.address_2,
                            city: student.city,
                            state: student.state,
                            country: student.country,
                            zip_code: student.zip_code,
                          },
                          { highlight: searchParams.get("sortBy") }
                        )}
                      </TableCell>
                      <TableCell>
                        {dayjs(student.created_at).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>
                        <EditOnHover
                          to={`/instructors/${student.instructor_id}/profile`}
                        >
                          <Link
                            className={hoverLink}
                            to={`/instructors/${student.instructor_id}/overview`}
                          >
                            {student.instructor_name}
                          </Link>
                        </EditOnHover>
                        {student.student_shares.length > 0 && (
                          <ul className="ml-2 text-xs text-yellow-500">
                            {student.student_shares.map((share) => (
                              <li key={share.id}>{share.user_name}</li>
                            ))}
                          </ul>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          className={hoverLink}
                          to={`/schools/${student.school.id}/overview`}
                        >
                          (#{student.school.school_code}){" "}
                          {student.school.school_name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Button
                          style={{
                            display:
                              "none" /* TODO: Remove this when functionality is added */,
                          }}
                          onClick={() =>
                            setPrintTicketModal({
                              open: true,
                              student: student,
                            })
                          }
                          variant="primary-outline"
                        >
                          Print Ticket
                        </Button>
                      </TableCell>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Pagination>
        </Card>
      </div>

      <Modal
        open={printTicketModal.open}
        setOpen={() => setPrintTicketModal({ open: false, student: null })}
      >
        <PrintTicket
          student={printTicketModal.student}
          closeModal={() => setPrintTicketModal({ open: false, student: null })}
        />
      </Modal>

      <Modal
        open={csvPreviewModal.open}
        setOpen={() => setCsvPreviewModal({ open: false })}
      >
        <CsvStudents
          closeModal={() => {
            setCsvPreviewModal({ open: false });
            document.getElementById("csv-reader-input").value = null;
            setTimeout(() => setShowCsvUpload(false), 500);
          }}
          csvStudents={csvStudents}
          setRefreshStudents={setRefreshStudents}
        />
      </Modal>

      <ReassignStudents
        open={reassignStudentsOpen}
        students={selectedStudents}
        setOpen={setReassignStudentsOpen}
        onSuccess={getStudents}
      />

      <ShareStudents
        open={shareStudentsOpen}
        students={selectedStudents}
        setOpen={setShareStudentsOpen}
        onSuccess={getStudents}
      />

      <UnshareStudents
        open={unshareStudentsOpen}
        students={selectedStudents}
        setOpen={setUnshareStudentsOpen}
        onSuccess={getStudents}
      />

      <StudentsPrint students={printingStudents} />
    </>
  );
}
