import axiosClient from "../utils/axios";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/series`;
const addSchoolEndPoint = `${baseEndpoint}/series_offered`;

const getAllSeminars = async (params) => {
  const data = await axiosClient.get(endpoint, {
    params: { type: "Seminar", ...params.params },
  });

  return data;
};

const getAllSeminarPresentationsRequest = async (params) => {
  return await axiosClient.get(`${endpoint}/${params.seminarId}/episodes`);
};

const getAllSeminarStudentsRequest = async (seminarId, params) =>
  await axiosClient.get(`${endpoint}/${seminarId}/students`, params);

const getAllSeminarAssignStudentsRequest = async (params) => {
  return await axiosClient.get(
    `${endpoint}/${params.seminarId}/assign_students`
  );
};

const getAllParticipatingSchoolsInstructors = async (params) => {
  return await axiosClient.get(
    `${endpoint}/${params.seminarId}/participating_schools_instructors`
  );
};

const assignStudentRequest = async (params) => {
  return await axiosClient.put(
    `${endpoint}/${params.seminarId}/assign_student`,
    params
  );
};

const getAllSeminarDecisionsRequest = async (params) => {
  return await axiosClient.get(
    `${endpoint}/${params.seminarId}/decision_options`
  );
};

const getAllSeminarProductsRequest = async (params) => {
  return await axiosClient.get(`${endpoint}/${params.seminarId}/products`);
};

const createSeminarRequest = async (params) => {
  return await axiosClient.post(endpoint, params);
};

const updateSeminarRequest = async (params) => {
  return await axiosClient.put(`${endpoint}/${params.seminarId}`, params);
};

const getSeminarRequest = async (params) => {
  return await axiosClient.get(`${endpoint}/${params.seminarId}`);
};

const deleteSeminarRequest = async (params) => {
  return await axiosClient.delete(`${endpoint}/${params.seminarId}`);
};

const getSeminarAttendancesRequest = async (params) => {
  return await axiosClient.get(`${endpoint}/${params.seminarId}/attendances`, {
    params: params,
  });
};

const addSchoolRequest = async (params) => {
  return await axiosClient.post(addSchoolEndPoint, params);
};

const deleteSchoolRequest = async (params) => {
  return await axiosClient.delete(
    `${addSchoolEndPoint}/${params.series_offered_id}`,
    {
      params: params,
    }
  );
};

const getRecentlyAssignedStudentsRequest = async (params) => {
  return await axiosClient.get(
    `${endpoint}/${params.seminarId}/recently_assigned`
  );
};

export {
  createSeminarRequest,
  getAllSeminarPresentationsRequest,
  getAllSeminarStudentsRequest,
  getAllSeminarDecisionsRequest,
  getSeminarRequest,
  getAllSeminars,
  updateSeminarRequest,
  deleteSeminarRequest,
  getAllSeminarProductsRequest,
  getSeminarAttendancesRequest,
  getAllSeminarAssignStudentsRequest,
  getAllParticipatingSchoolsInstructors,
  assignStudentRequest,
  addSchoolRequest,
  deleteSchoolRequest,
  getRecentlyAssignedStudentsRequest,
};
