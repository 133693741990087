import React, { useEffect, useState } from "react";
import ActionMenuContainer from "../../ActionMenuContainer";
import { getAllSchools } from "../../../../requests/schools";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../../UI/Button";
import InputGroup from "../../../UI/Inputs/InputGroup";
import SearchSchool from "../../../UI/SearchSchool";

export default function SchoolSelect({ setRender }) {
  const navigate = useNavigate();
  const methods = useForm();
  const [schools, setSchool] = useState([]);

  const onSubmit = (data) =>
    navigate(`/seminars/new?school_id=${data.school?.value}`);

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchool(res.data.schools);
    }
  };

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <ActionMenuContainer
      label="Select a school"
      handleGoingBack={() => setRender(null)}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="md:w-2/4 w-full mb-4">
            {schools.length > 0 && (
              <InputGroup>
                <SearchSchool
                  id="school"
                  options={schools.map((school) => ({
                    value: school.id,
                    label: school.display_name,
                  }))}
                  isRequired
                />
              </InputGroup>
            )}
          </div>

          <div className="text-center">
            <Button type="submit" variant="primary">
              Create New Seminar
            </Button>
          </div>
        </form>
      </FormProvider>
    </ActionMenuContainer>
  );
}
