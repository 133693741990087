import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import TableCell from "../../../UI/Table/TableCell";
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid";
import { updatePresentationRequest } from "../../../../requests/presentations";
import SeminarPresentationForm from "./SeminarPresentationForm";
import { ToastNotification } from "../../../UI/ToastNotification";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { displayError } from "../../../../utils/displayError";
import { hoverLink } from "../../../../utils/hoverLink";
import Modal from "../../../UI/Modal";
import TakeAttendance from "./Attendance/TakeAttendance";
import ActionMenuContainer from "../../../ActionMenu/ActionMenuContainer";
import { twMerge } from "tailwind-merge";

export default function SeminarPresentationRow({
  presentation,
  handleDeleteModal,
  formOpen,
  setFormOpen,
}) {
  const [editing, setEditing] = useState(false);
  const [attendanceModal, setAttendanceModalOpen] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await updatePresentationRequest({
        ...data,
        presentationId: presentation.id,
      });

      if (res.data) {
        ToastNotification("success", "Presentation updated successfully.");
        setFormOpen({ open: false, action: null });
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleOpenForm = () => {
    setFormOpen({ open: true, action: "edit" });
    setEditing(true);
  };

  useEffect(() => {
    if (!formOpen.open && formOpen.action === null) {
      setEditing(false);
    }
  }, [formOpen]);

  return (
    <tr>
      {editing ? (
        <TableCell colSpan={5}>
          <SeminarPresentationForm
            onSubmit={onSubmit}
            presentation={presentation}
            setOpen={setFormOpen}
          />
        </TableCell>
      ) : (
        <>
          <TableCell>
            <p onClick={() => handleOpenForm()} className={hoverLink}>
              {presentation.name}
            </p>
          </TableCell>
          <TableCell>{presentation.date}</TableCell>
          <TableCell>{presentation.attendances}</TableCell>
          <TableCell>{presentation.decision_card_name}</TableCell>
          <TableCell>
            <div
              onClick={() => setAttendanceModalOpen(true)}
              className={twMerge(hoverLink, "flex items-center cursor-pointer")}
            >
              <CheckCircleIcon className="mr-2 w-5" />
              <p>Take Attendance</p>
            </div>
          </TableCell>
          <TableCell>
            <Menu as="div">
              <Menu.Button className="hover:bg-white/25 bg-transparent rounded rounded-full w-6">
                <EllipsisVerticalIcon className="w-6 cursor-pointer" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-10 mr-4 z-10 p-3 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item
                    onClick={(e) => handleDeleteModal(e, presentation.id)}
                    className="hover:bg-secondary-600 hover:text-white p-2 cursor-pointer"
                  >
                    <p className="flex">
                      <TrashIcon className="w-5 mr-2" />
                      <span>Delete</span>
                    </p>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </TableCell>
        </>
      )}

      <Modal dark open={attendanceModal} setOpen={setAttendanceModalOpen}>
        <ActionMenuContainer
          label={`Take Attendance for ${presentation.name}`}
          clickToClose={() => setAttendanceModalOpen(false)}
        >
          <TakeAttendance presentation={presentation} />
        </ActionMenuContainer>
      </Modal>
    </tr>
  );
}
