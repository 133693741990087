import React from "react";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import CardHeader from "../../../UI/CardHeader";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { Link } from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";
import EmptyTable from "../../../UI/EmptyTable";

export default function OnlineCourses({ student }) {
  return (
    <>
      <CardHeader title="Online Courses" />

      {/* eslint-disable no-constant-condition */}
      {false ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>PROGRESS</TableHeader>
              <TableHeader>DECISION</TableHeader>
              <TableHeader>STATUS</TableHeader>
            </tr>
          </thead>
          <tbody>
            {student.course_enrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <TableCell>
                  <Link
                    to={`/students/${student.id}/enrollments/${enrollment.id}/management`}
                    className={hoverLink}
                  >
                    {enrollment.series_name}
                  </Link>
                </TableCell>
                <TableCell>6/14</TableCell>
                <TableCell>{enrollment.decisions_count}</TableCell>
                <TableCell>
                  {returnEnrollmentStatus(enrollment.series_id, enrollment)}
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No online courses for this student." />
      )}
    </>
  );
}
