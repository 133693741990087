import React, { useEffect, useRef } from "react";
import Input from "../../../UI/Inputs/Input";
import AddButton from "../../../UI/AddButton";
import Checkbox from "../../../UI/Inputs/Checkbox";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { useFieldArray, useFormContext } from "react-hook-form";
import RoleSelectRow from "./RoleSelectRow";
import { useSearchParams } from "react-router-dom";

export default function AccountForm({ instructor }) {
  const [searchParams] = useSearchParams();
  const { control, watch } = useFormContext();

  const { fields, append, update } = useFieldArray({
    control,
    name: "roles_attributes",
  });

  const appendCalled = useRef(false);

  useEffect(() => {
    if (searchParams.get("school_id") && !appendCalled.current) {
      append({
        role_type: "school_admin",
        organization_id: searchParams.get("school_id"),
      });
      appendCalled.current = true; //This prevents useEffect from triggering twice.
    }
  }, []);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Password</h2>

      <div className="mb-6">
        <InputGroup col>
          {instructor && (
            <Input
              id="current_password"
              type="password"
              placeholder="Current Password"
            />
          )}
          <Input
            id="password"
            type="password"
            placeholder="New Password"
            isRequired={!instructor}
          />
          <Input
            id="password_confirmation"
            type="password"
            placeholder="New Password Confirmation"
            isRequired={!instructor}
          />
        </InputGroup>

        <p className="mb-6 text-sm">
          Password needs uppercase, lowercase, number, special character and at
          least 9 characters.
        </p>
      </div>

      <div className="mb-6 border-b border-black h-px"></div>

      <h2 className="mb-6 text-2xl">Options</h2>

      <div className="mb-6">
        <div className="mb-6">
          <Checkbox
            id="receiveFollowUp"
            label="Receive notifications by email"
          />
        </div>
        <div>
          <Checkbox
            id="admin"
            defaultChecked={instructor?.admin}
            label="Global Admin?"
          />
        </div>
      </div>

      {!watch("admin", instructor?.admin) && (
        <>
          <div className="mb-6 border-b border-black h-px"></div>

          <h2 className="mb-6 text-2xl">Roles</h2>

          {fields
            .filter((activeField) => !activeField.delete)
            .map((field, index) => (
              <RoleSelectRow
                key={field.id}
                field={field}
                index={index}
                update={update}
              />
            ))}

          <div className="mb-6">
            <AddButton
              onClick={() => append({ role_type: "instructor" })}
              label="Add additional role"
              variant="primary-outline"
            />
          </div>
        </>
      )}
    </div>
  );
}
