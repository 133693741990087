import React from "react";
import Print from "../../UI/Print";
import { fullName } from "../../../utils/fullName";
import { renderPresentations } from "../../../utils/reports/reportAttendanceChecks";
import { addressMultiLine } from "../../../utils/fullAddress";

export default function AttendanceDecisionReportPrint({
  reports,
  handleAfterPrint,
}) {
  return (
    <Print
      header="ATTENDANCE DECISION REPORT"
      empty={reports?.enrollments?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>REGISTRATION NUMBER</th>
            <th>NAME/PHONE</th>
            <th>ADDRESS</th>
            <th>ATTENDANCE</th>
            <th>DECISIONS</th>
            <th>INSTRUCTOR</th>
            <th>SCHOOL</th>
          </tr>
        </thead>
        <tbody>
          {reports?.enrollments?.map((enrollment) => (
            <tr key={enrollment.id}>
              <td>{enrollment.registration_number || "-"}</td>
              <td>
                {fullName(
                  enrollment.student.first_name,
                  "",
                  enrollment.student.last_name
                )}
                <br />
                {enrollment.student.phone}
              </td>
              <td>
                {addressMultiLine({
                  address: enrollment.student.address,
                  address_2: enrollment.student.address_2,
                  city: enrollment.student.city,
                  state: enrollment.student.state,
                  zip_code: enrollment.student.zip_code,
                  country: enrollment.student.country,
                })}
              </td>
              <td>
                <div className="flex">
                  {reports?.presentations.length > 0 &&
                    renderPresentations(reports, enrollment)}
                </div>
              </td>
              <td>
                {enrollment.decision_options
                  ?.map((decision) => decision.name)
                  .join(", ")}
              </td>
              <td>{enrollment.student.instructor_name}</td>
              <td>
                {`(#${enrollment.student.school.school_code}) ${enrollment.student.school.church_name}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
