import React from "react";
import Print from "../../UI/Print";
import { fullName } from "../../../utils/fullName";
import { renderPresentations } from "../../../utils/reports/reportAttendanceChecks";

export default function AttendanceReportPrint({ reports, handleAfterPrint }) {
  return (
    <Print
      header="ATTENDANCE REPORT"
      empty={reports?.enrollments?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>REGISTRATION NUMBER</th>
            <th>NAME/PHONE</th>
            <th>ATTENDANCE</th>
            <th>INSTRUCTOR</th>
            <th>SCHOOL</th>
          </tr>
        </thead>
        <tbody>
          {reports?.enrollments?.map((enrollment) => (
            <tr key={enrollment.id}>
              <td>{enrollment.registration_number || "-"}</td>
              <td>
                {fullName(
                  enrollment.student.first_name,
                  "",
                  enrollment.student.last_name
                )}
                <br />
                {enrollment.student.phone}
              </td>
              <td>
                <div className="flex">
                  {reports?.presentations.length > 0 &&
                    renderPresentations(reports, enrollment)}
                </div>
              </td>
              <td>{enrollment.student.instructor_name}</td>
              <td>
                {`(#${enrollment.student.school.school_code}) ${enrollment.student.school.church_name}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
