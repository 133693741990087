import React from "react";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import { fullName } from "../../../../utils/fullName";
import MobileResults from "./Mobile/MobileResults";

export default function Results({ students }) {
  if (students.length === 0) {
    return (
      <p className="text-center border-b border-white pb-6">
        No students found.
      </p>
    );
  }

  return (
    <>
      <div className="sm:hidden block">
        <MobileResults students={students} />
      </div>
      <div className="sm:block hidden">
        <Table>
          <thead>
            <tr>
              <TableHeader>NAME</TableHeader>
              <TableHeader>ENROLLMENTS</TableHeader>
              <TableHeader>ADDRESS</TableHeader>
              <TableHeader>CREATED</TableHeader>
              <TableHeader>INSTRUCTOR</TableHeader>
              <TableHeader>SCHOOL</TableHeader>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => (
              <tr key={student.id}>
                <TableCell>
                  {fullName(
                    student.first_name,
                    student.middle_name,
                    student.last_name
                  )}
                </TableCell>
                <TableCell>Enrollments</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>
                  {fullName(
                    student.instructor.first_name,
                    " ",
                    student.instructor.last_name
                  )}
                </TableCell>
                <TableCell>{student.school.school_name}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
