import React, { useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useRouteLoaderData,
} from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../../UI/Card";
import Modal from "../../../UI/Modal";
import ContactInformationForm from "./ContactInformationForm";
import DemographicsForm from "./DemographicsForm";
import {
  deleteStudentRequest,
  updateStudentRequest,
} from "../../../../requests/students";
import { useParams } from "react-router-dom";
import { ToastNotification } from "../../../UI/ToastNotification";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Button from "../../../UI/Button";
import RecentChanges from "../../../Versions/RecentChanges";
import { checkPermission } from "../../../../utils/checkPermission";
import { displayError } from "../../../../utils/displayError";
import Instructors from "./Instructors";

export default function StudentProfile() {
  const { user } = useOutletContext();

  const student = useRouteLoaderData("student").data;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const methods = useForm();
  const params = useParams();
  const studentId = params.studentId;
  const navigate = useNavigate();

  const defaultStudentShares = student?.student_shares.map((share) => ({
    id: share.user_id,
    share_id: share.id,
    name: share.user_name,
  }));

  const onSubmit = async (data) => {
    data.married = data.married === "Married";

    try {
      const selectedSharedWith = data.shared_with.map((share) => ({
        id: share.share_id,
        student_id: studentId,
        user_id: share.id,
        _destroy: false,
      }));

      const sharesToDestroy = student?.student_shares
        .filter(
          (defaultShare) =>
            !data.shared_with.some((s) => s.id === defaultShare.user_id)
        )
        .map((share) => ({
          id: share.id,
          user_id: share.user_id,
          student_id: studentId,
          _destroy: true,
        }));

      const finalShares = [...selectedSharedWith, ...sharesToDestroy];

      const res = await updateStudentRequest({
        ...data,
        instructor_id: data.instructor_id.split("#")[0],
        school_id: data.instructor_id.split("#")[1],
        studentId: studentId,
        student_shares_attributes: finalShares,
      });

      if (res.data) {
        ToastNotification("success", "Student updated successfully.");
        navigate(`/students/${res.data.id}/profile`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deleteStudent = async () => {
    try {
      const res = await deleteStudentRequest({ studentId: studentId });

      if (res) {
        ToastNotification("success", "Student deleted successfully.");
        navigate(`/students`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const isAdmin =
    user.roles.find(
      (role) =>
        role.role_type === "school_admin" &&
        role.organization_id === student?.school_id
    ) || checkPermission("conference_admin");

  if (student) {
    return (
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Card classNames="p-6">
              <div className="flex md:flex-row flex-col">
                <div className="md:w-3/5 md:border-r md:border-black md:pr-6">
                  <ContactInformationForm student={student} isAdmin={isAdmin} />
                </div>
                <div className="md:w-2/5 md:pl-6">
                  <Instructors
                    student={student}
                    isAdmin={isAdmin}
                    defaultStudentShares={defaultStudentShares}
                  />
                  <DemographicsForm student={student} isAdmin={isAdmin} />
                </div>
              </div>

              {isAdmin && (
                <RecentChanges itemType="student" itemId={student.id} />
              )}
            </Card>
            {isAdmin && (
              <div className="md:flex-row flex-col flex items-center p-6">
                <Button
                  classNames="md:w-auto w-full mb-4 mr-4"
                  variant="primary"
                  type="submit"
                >
                  Update Student
                </Button>
                <Button
                  classNames="md:w-auto w-full mb-4 mr-4"
                  variant="secondary"
                  onClick={(e) => handleDeleteModal(e)}
                >
                  Delete Student
                </Button>
                {student?.course_enrollments.length > 0 && (
                  <p>This student has course data</p>
                )}
              </div>
            )}
          </form>
        </FormProvider>

        <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
          <ConfirmationDialog
            closeModal={() => setDeleteModalOpen(false)}
            confirmAction={() => deleteStudent()}
          />
        </Modal>
      </div>
    );
  }
}
