import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import ListButtonGroup from "../../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../../UI/ListButtonGroup/ListButtonGroupItem";
import { fullName } from "../../../../utils/fullName";
import EmptyTable from "../../../UI/EmptyTable";
import { getSeminarAttendancesRequest } from "../../../../requests/seminars";
import { displayError } from "../../../../utils/displayError";
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";
import { updateEnrollmentRequest } from "../../../../requests/enrollments";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function SeminarAttendance() {
  const params = useParams();
  const [attendances, setAttendances] = useState([]);
  const { user } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get("section") || "active";

  const getAttendances = async () => {
    try {
      const res = await getSeminarAttendancesRequest({
        seminarId: params.seminarId,
        filter: searchParams.get("section"),
      });

      if (res.data) {
        setAttendances(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const tableNavigation = [
    {
      label: "Active",
    },
    {
      label: "Hidden",
    },
  ];

  const checkboxHeaders = Array.from(
    { length: attendances?.presentations?.length },
    (_, index) => <TableHeader key={index}>{index + 1}</TableHeader>
  );

  const onSubmit = async (enrollment, episodeDelivery, presentation) => {
    try {
      const rest = await updateEnrollmentRequest({
        episode_deliveries_attributes: [
          {
            id: episodeDelivery?.id,
            date_completed: episodeDelivery?.date_completed ? null : new Date(),
            episode_id: presentation.id,
            grading_instructor_id: user.id,
            delivery_type: "Personal Delivery",
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (rest.data) {
        ToastNotification("success", "Attendance taken successfully");
        getAttendances();
      }
    } catch (e) {
      displayError(e);
    }
  };

  const findEpisodeDelivery = (enrollment, presentation) => {
    return enrollment.episode_deliveries.find(
      (episode_delivery) => episode_delivery.episode_id === presentation.id
    );
  };

  const checkboxCell = (enrollment) =>
    attendances?.presentations?.map((presentation, index) => (
      <TableCell key={index}>
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
          onClick={() =>
            onSubmit(
              enrollment,
              findEpisodeDelivery(enrollment, presentation),
              presentation
            )
          }
          defaultChecked={
            !!findEpisodeDelivery(enrollment, presentation)?.date_completed
          }
        />
      </TableCell>
    ));

  const handleSection = (section) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      return prev;
    });
  };

  const onHide = async (enrollment) => {
    try {
      const res = await updateEnrollmentRequest({
        active: section !== "active",
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        getAttendances();
        // navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getAttendances();
  }, [section]);

  return (
    <Card>
      <div className="flex items-center justify-between mb-6">
        <div className="relative">
          <h1 className="text-xl font-bold text-primary">ATTENDANCE</h1>
        </div>
        <ListButtonGroup>
          {tableNavigation.map((item, index) => (
            <ListButtonGroupItem
              key={index}
              label={item.label}
              name={item.label.toLowerCase()}
              isFirst={index === 0}
              isLast={index === tableNavigation.length - 1}
              setSelected={handleSection}
              selected={section}
            />
          ))}
        </ListButtonGroup>
      </div>
      {attendances?.enrollments?.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>STUDENT</TableHeader>
              <TableHeader>PRESENTATIONS</TableHeader>
              {checkboxHeaders}
              <TableHeader>
                {section === "active" ? "HIDE" : "UNHIDE"}
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {attendances.enrollments.map((enrollment) => (
              <tr key={enrollment.id}>
                <TableCell>
                  <Link
                    to={`/students/${enrollment.student.id}/overview`}
                    className={hoverLink}
                  >
                    {fullName(
                      enrollment.student.first_name,
                      " ",
                      enrollment.student.last_name
                    )}
                  </Link>
                </TableCell>
                <TableCell>{enrollment.attendance_progress}</TableCell>
                {attendances.presentations.length > 0 &&
                  checkboxCell(enrollment)}
                <TableCell>
                  <Button
                    variant="primary-outline"
                    onClick={() => onHide(enrollment)}
                  >
                    {section === "active" ? "Hide" : "Unhide"}
                  </Button>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No attendances recorded." />
      )}
    </Card>
  );
}
