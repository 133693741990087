import React, { useEffect, useState } from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import { Link, useRouteLoaderData } from "react-router-dom";
import { getAllSchoolSeminars } from "../../../../requests/schools";
import EmptyTable from "../../../UI/EmptyTable";
import { hoverLink } from "../../../../utils/hoverLink";

export default function SchoolSeminars() {
  const school = useRouteLoaderData("school").data;
  const [seminars, setSeminars] = useState([]);

  const getSeminars = async () => {
    const res = await getAllSchoolSeminars({ school_id: school.id });
    if (res.data) {
      setSeminars(res.data);
    }
  };

  useEffect(() => {
    getSeminars();
  }, []);

  return (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            <img
              src="/add-new.svg"
              className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
              width={30}
              height={30}
              alt="Add New"
            />

            <h1 className="text-xl font-bold text-primary">SEMINARS</h1>
          </div>
        </div>
        {seminars.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>SEMINAR</TableHeader>
                <TableHeader>PRESENTATIONS</TableHeader>
                <TableHeader>ACTIVE STUDENTS</TableHeader>
                <TableHeader>TOTAL STUDENTS</TableHeader>
                <TableHeader>PRODUCTS</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {seminars.map((seminar) => (
                <tr key={seminar.id}>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/seminars/${seminar?.id}/details`}
                    >
                      {seminar.name}
                    </Link>
                  </TableCell>
                  <TableCell>{seminar.presentations_count}</TableCell>
                  <TableCell>{seminar.active_students_count}</TableCell>
                  <TableCell>{seminar.students_count}</TableCell>
                  <TableCell>#</TableCell>
                  <TableCell>
                    <Button variant="primary-outline">Archive</Button>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No seminars found." />
        )}
      </Card>
    </div>
  );
}
