import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFilterValues } from "../UI/Filter/Filter";
import Card from "../UI/Card";
import { Pagination, usePagination } from "../UI/Pagination";
import { getAllVersions } from "../../requests/versions";
import VersionsFilter from "./VersionsFilter";
import VersionsTable from "./VersionsTable";

let timer;

export default function Versions() {
  const filterValues = useFilterValues();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pagination = usePagination();
  const perPage = pagination.recordsPerPage;
  const [versions, setVersions] = useState();
  const [loading, setLoading] = useState(false);

  const fetchVersions = () => {
    setLoading(true);

    getAllVersions({
      params: {
        page: page,
        per_page: perPage,
        q: filterValues,
      },
    })
      .then((res) => {
        setVersions(res.data.versions);
        pagination.setData(res.data.pagination);

        window.setTimeout(() => {
          window.scrollTo({ top: 0 });
        }, 100);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVersions();

    return () => {
      window.clearTimeout(timer);
    };
  }, [searchParams, pagination.recordsPerPage]);

  return (
    <Card>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-bold text-primary mr-8">VERSIONS</h1>
        <VersionsFilter />
      </div>

      <Pagination
        pagination={pagination}
        loading={loading && versions?.length < 1}
        empty={versions?.length < 1}
      >
        <VersionsTable versions={versions} loading={loading} />
      </Pagination>
    </Card>
  );
}
