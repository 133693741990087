import React from "react";
import Print from "../../UI/Print";
import { addressMultiLine } from "../../../utils/fullAddress";

export default function ClaimedZipsReportPrint({ reports, handleAfterPrint }) {
  return (
    <Print
      header="CLAIMED ZIPS REPORT"
      empty={reports?.schools?.length === 0}
      handleAfterPrint={handleAfterPrint}
    >
      <table>
        <thead>
          <tr>
            <th>NAME</th>
            <th>ADDRESS</th>
            <th>CONFERENCE</th>
            <th>CLAIMED ZIPS</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {reports?.schools?.map((school) => (
            <tr key={school.id}>
              <td>{`(#${school.school_code}) ${school.church_name}`}</td>
              <td>
                {addressMultiLine({
                  address: school.church_address,
                  address2: school.church_address_2,
                  city: school.church_city,
                  state: school.church_state,
                  zip: school.church_zip_code,
                })}
              </td>
              <td>{school.conference?.name}</td>
              <td>
                {school.claimed_zips.map((claimed_zip) => (
                  <p key={claimed_zip.id}>{claimed_zip.zip}</p>
                ))}
              </td>
              <td>{school.active ? "Active" : "Inactive"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Print>
  );
}
