import React, { useEffect, useState } from "react";
import { getAllStudents } from "../../../../requests/students";
import CreateNewStudent from "./CreateNewStudent";
import PlaceManually from "./PlaceManually";
import Search from "./Search";
import ActionMenuContainer from "../../ActionMenuContainer";
import NewStudentForm from "./NewStudentForm";
import SuggestedSchools from "./SuggestedSchools";
import Success from "./Success";

export default function NewStudent({ setRender }) {
  const [students, setStudents] = useState([]);
  const [isShowingResults, setIsShowingResults] = useState(false);
  const [createNewStudent, setCreateNewStudent] = useState(false);
  const [placeManually, setPlaceManually] = useState(false);
  const [search, setSearch] = useState(null);
  const [newStudentForm, setNewStudentForm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [suggestedSchools, setSuggestedSchools] = useState(false);
  const [school, setSchool] = useState(null);
  const [studentData, setStudentData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [courses, setCourses] = useState([]);
  const [studentSearchName, setStudentSearchName] = useState();

  // Using Ransacker gem Search Matcher `*_i_cont_all` pattern.
  // Splitting the query terms into an array for the `cont` matcher.
  const getSearch = async () => {
    const res = await getAllStudents({
      params: {
        per_page: 10000,
        q: { full_name_i_cont_all: search.trim().split(" ") },
      },
    });

    if (res.data) {
      setStudents(res.data.students);
      setIsShowingResults(true);
    }
  };

  const onSubmit = (data) => {
    setSearch(data.search);
  };

  const handleGoingBack = () => {
    if (createNewStudent || placeManually) {
      setCreateNewStudent(false);
      setPlaceManually(false);
    } else if (newStudentForm) {
      setNewStudentForm(false);
      setCreateNewStudent(true);
    } else if (suggestedSchools) {
      setSuggestedSchools(false);
      setNewStudentForm(true);
    } else {
      setRender(null);
    }
  };

  useEffect(() => {
    if (search) {
      getSearch();
    }
  }, [search]);

  const renderContent = () => {
    if (success) {
      return (
        <Success
          setSuccess={setSuccess}
          setSuggestedSchools={setSuggestedSchools}
          setCreateNewStudent={setCreateNewStudent}
          school={school}
          selectedCourseId={selectedCourseId}
          studentData={studentData}
        />
      );
    }

    if (suggestedSchools) {
      return (
        <SuggestedSchools
          setSuggestedSchools={setSuggestedSchools}
          setSuccess={setSuccess}
          selectedCourseId={selectedCourseId}
          zipCode={studentData.zip_code}
          setSchool={setSchool}
        />
      );
    }

    if (newStudentForm) {
      return (
        <NewStudentForm
          setNewStudentForm={setNewStudentForm}
          setSuggestedSchools={setSuggestedSchools}
          studentData={studentData}
          setStudentData={setStudentData}
          studentSearchName={studentSearchName}
        />
      );
    }

    if (createNewStudent) {
      return (
        <CreateNewStudent
          setCreateNewStudent={setCreateNewStudent}
          setNewStudentForm={setNewStudentForm}
          selectedCourseId={selectedCourseId}
          setSelectedCourseId={setSelectedCourseId}
          courses={courses}
          setCourses={setCourses}
        />
      );
    }

    if (placeManually) {
      return <PlaceManually />;
    }

    return (
      <Search
        students={students}
        onSubmit={onSubmit}
        isShowingResults={isShowingResults}
        setCreateNewStudent={setCreateNewStudent}
        setPlaceManually={setPlaceManually}
        setStudentSearchName={setStudentSearchName}
      />
    );
  };

  return (
    <>
      {success ? (
        <ActionMenuContainer label="Student Added Successfully">
          {renderContent()}
        </ActionMenuContainer>
      ) : (
        <ActionMenuContainer
          label="Add New student"
          handleGoingBack={() => handleGoingBack()}
        >
          {renderContent()}
        </ActionMenuContainer>
      )}
    </>
  );
}
